<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white grid grid-cols-12 " :style="`height:` +  mainHeight + `px; !important`">
        <div class=" mx-2 shadow-md relative" :class="isFilterOpen ? 'box col-span-10' : 'box col-span-12'">
          <div class="bg-white cust_card p-2 heading-3 text-text1 justify-between flex">
            <p>COGS by Product (Walmart)</p>
            <div class="top-0 absolute" :class="isFilterOpen ? '-right-4' : 'transition-all delay-75 -right-2'" >
              <button id="toggle" class="bg-gray-600 hover:bg-gray-700 transition delay-75 w-11 h-11 rounded-l-full " @click="openCloseFilter()">
                <span v-if="isFilterOpen"><i class="fas fa-caret-right h-6 w-6 text-white mt-1"></i></span>
                <p v-if="!isFilterOpen"><i class="fas fa-caret-left h-6 w-6 text-white mt-1"></i></p>
              </button>
            </div>
          </div>
          <div style="overflow-y: auto; white-space: nowrap;overflow-x: hidden;" class="table_containder" v-if="reportListing.length > 0 && firstLoading">
            <div :style="`max-height:` +  cardHight + `px; !important`" class="pt-2">
              <div id="chart" class="graphic-container">
                <apexchart :height="chartHeight" type="bar" :options="chartOptions" :series="series1"></apexchart>
              </div>
            </div>
          </div>
          <div class="layout m-2" v-if="reportListing.length === 0 && firstLoading">
            <div class="flex p-3 text-sm text-primary2 bg-white border-blue-100 border-2 rounded-lg  items-center" role="alert">
              <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
              <span class="sr-only">Info</span>
              <div>
                <p class=" font-medium heading-4">No record found.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="box col-span-2 overflow-y-auto mx-2 shadow-md transition-transform delay-300 ease-in-out transition-2" :style="!isFilterOpen ? 'display: none' : ''">
          <GlobalFilter :callFrom="'Walmart'" :callParent="'accounting'"  @closeFilter="closeFilter" :isFilterOpen="isFilterOpen"/>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import moment from 'moment'
import API from '@/api/App.js'
import GlobalFilter from '@/View/Admin/Components/filterWithoutSelection.vue'
// import Pagination from '@/View/components/pagination.vue'
export default {
  components: {
    GlobalFilter,
  },
  data () {
    return {
      chartHeight: '2000',
      series1: [],
      series: [
        {
          name: 'Profite Margin',
          data: [1000, 246, 456, 34, 47, 222, 15, 30, 710, 172, 120]
        }, {
          name: 'Sum of Item Price (Order Total)',
          data: [20, 2000, 8, 634, 14, 360, 22, 168, 506, 30, 614]
        }, {
          name: 'Sum of Tax',
          data: [30, 256, 3000, 18, 154, 816, 620, 24, 23, 10, 273]
        }
      ],
      chartOptions: {
        chart: {
          barWidth: 210,
          type: 'bar',
          height: 400,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: false,
                offsetX: 0,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: [],
          labels: {
            formatter: function (val) {
              return val + ""
            }
          }
        },
        // yaxis: {
        //   min: 0,
        //   max: 1200,
        //   tickAmount : 20
        // },
        tooltip: {
          custom: function({series, seriesIndex, dataPointIndex, w}) {
            console.log('series', series)
            console.log('seriesIndex', seriesIndex)
            console.log('dataPointIndex', dataPointIndex)
            console.log('w', w)
            return '<ul style="padding:4px">' +
            '<li><b>Sku</b>: ' + w.config.xaxis.categories[dataPointIndex] + '</li>' +
            '<li><b>' + w.config.series[seriesIndex].name + '</b>: $' + w.config.series[seriesIndex].data[dataPointIndex] + '</li>' +
            '</ul>';
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40
        }
      },
      isFilterOpen: true,
      reportListing: [],
      tempobj: [
        {title: "Order Total", value: "$817,93527.00"},
        {title: "Refund Total", value: "$1237.00"},
        {title: "Promo Rebates", value: "$43.00"},
        {title: "FBA Fees Total", value: "$11.00"},
        {title: "Advertising Expense", value: "$0.00"},
        {title: "Shipping Expense", value: "$25.00"},
        {title: "Amazon Fees", value: "$0.00"},
        {title: "Inventory Return", value: "$67.00"},
        {title: "Revenue-Shipping", value: "$93.00"},
        {title: "Revenue-Produce", value: "$456.00"},
      ],
      filterObject: {
        startDate: '',
        endDate: '',
        sendItemId: [],
        minDate: '',
        maxDate: '',
      },
      cardHight: 0,
      mainHeight: 0,
      defaultStartDate: '',
      defaultEndDate: '',
      firstLoading: false,
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    this.cardHight = window.innerHeight - 171
    this.mainHeight = window.innerHeight - 90
    console.log('this.cardHight', this.cardHight, this.mainHeight)
    this.defaultEndDate = new Date()
    let currentDate = new Date()
    this.defaultStartDate = new Date(currentDate.setDate(currentDate.getDate() - 29))
    this.filterObject.startDate = this.defaultStartDate
    this.filterObject.endDate = this.defaultEndDate
    console.log('this.defaultStartDate', this.defaultStartDate)
    console.log('this.defaultEndDate', this.defaultEndDate)
    this.getListing()
  },
  watch: {
    filterObject: {
      handler () {
        console.log('filterObject', this.filterObject)
      },
      deep: true
    },
  },
  methods: {
    openCloseFilter () {
      if (this.isFilterOpen) {
        this.isFilterOpen = false
      } else {
        this.isFilterOpen = true
      }
      console.log('openCloseFilter')
    },
    closeFilter (data) {
      console.log('data', data)
      this.filterObject.startDate = data.startDate
      this.filterObject.endDate = data.endDate
      this.getListing()
    },
    getListing () {
      this.chartOptions.xaxis.categories = []
      this.series1 = []
      this.firstLoading = false
      console.log('this.filterObject.startDate 123 123', this.filterObject.startDate)
      console.log('this.filterObject.endDate 123 123', this.filterObject.endDate)
      let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
      let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait, it takes some time ...'})
      API.COGSProductTextOnlyReportListing(
        utcSTART,
        utcEND,
        false,
        response => {
          this.reportListing = response.Data === null ? [] : response.Data
          console.log(response.Data)
          this.firstLoading = true
          this.resetYearMonths()
          for (let index = 0; index < this.reportListing.length; index++) {
            this.chartOptions.xaxis.categories.push(this.reportListing[index].sku)
            this.series1[0].data.push(this.reportListing[index].profitMargin)
            this.series1[1].data.push(this.reportListing[index].sumOfItemPrice)
            this.series1[2].data.push(this.reportListing[index].sumOfTax)
          }
          if (this.chartOptions.xaxis.categories.length > 0 && this.chartOptions.xaxis.categories.length < 100) {
            this.chartHeight = '2000'
          } else if (this.chartOptions.xaxis.categories.length > 100 && this.chartOptions.xaxis.categories.length < 200) {
            this.chartHeight = '3500'
          } else if (this.chartOptions.xaxis.categories.length > 200 && this.chartOptions.xaxis.categories.length < 300) {
            this.chartHeight = '5000'
          } else if (this.chartOptions.xaxis.categories.length > 300 && this.chartOptions.xaxis.categories.length < 400) {
            this.chartHeight = '7000'
          } else if (this.chartOptions.xaxis.categories.length > 400 && this.chartOptions.xaxis.categories.length < 500) {
            this.chartHeight = '9000'
          } else if (this.chartOptions.xaxis.categories.length > 500 && this.chartOptions.xaxis.categories.length < 600) {
            this.chartHeight = '10000'
          } else if (this.chartOptions.xaxis.categories.length > 600 && this.chartOptions.xaxis.categories.length < 700) {
            this.chartHeight = '12000'
          } else if (this.chartOptions.xaxis.categories.length > 700 && this.chartOptions.xaxis.categories.length < 800) {
            this.chartHeight = '14000'
          } else if (this.chartOptions.xaxis.categories.length > 800 && this.chartOptions.xaxis.categories.length < 900) {
            this.chartHeight = '16000'
          } else if (this.chartOptions.xaxis.categories.length > 900 && this.chartOptions.xaxis.categories.length < 1000) {
            this.chartHeight = '18000'
          } else if (this.chartOptions.xaxis.categories.length > 1000) {
            this.chartHeight = '20000'
          }
          setTimeout(() => {
            this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          }, 5000)
        },
        error => {
          this.resetPaginationValues = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    resetYearMonths () {
      this.series1 = []
      let chartTitle = ['Profite Margin', 'Sum of Item Price (Order Total)', 'Sum of Tax']
      for (let index = 0; index < chartTitle.length; index++) {
        this.series1.push({
          name: chartTitle[index],
          data: []
        })
      }
    },
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>

.dates_col {
  min-width: 240px !important;
  width: 240px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}

.small_col {
  min-width: 134px !important;
  width: 134px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.graphic-container {
  overflow-y: auto;
  overflow-x: hidden;
}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.large_col {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}


</style>
