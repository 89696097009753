<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white grid grid-cols-12 " :style="`height:` +  mainHeight + `px; !important`">
        <div class=" mx-2 shadow-md relative" :class="isFilterOpen ? 'box col-span-10' : 'box col-span-12'">
          <div class="bg-white cust_card p-2 heading-3 text-text1 justify-between flex">
            <p>COGS by Product (Walmart) (TEXT ONLY)</p>
            <button @click="exportReport()" :class="firstLoading && dealerPetstopListing.length > 0 ? 'text-white bg-primary2 cursor-pointer' : 'cursor-not-allowed bg-gray-300 tet-text2 filter opacity-60'" style="letter-spacing: 1px;" class="px-2 cust_card heading-6 mr-8 centerLine">
              Export to Excel
            </button>
            <div class="top-0 absolute" :class="isFilterOpen ? '-right-4' : 'transition-all delay-75 -right-2'" >
              <button id="toggle" class="bg-gray-600 hover:bg-gray-700 transition delay-75 w-11 h-11 rounded-l-full " @click="openCloseFilter()">
                <span v-if="isFilterOpen"><i class="fas fa-caret-right h-6 w-6 text-white mt-1"></i></span>
                <p v-if="!isFilterOpen"><i class="fas fa-caret-left h-6 w-6 text-white mt-1"></i></p>
              </button>
            </div>
          </div>
          <div style="overflow-y: auto; white-space: nowrap;" class="table_containder mt-2 max-w-max relative" v-if="firstLoading">
            <div class="text-text2  min-w-min heaer_row border p-2 border-gray1 font-bold flex heading-6 py-1 items-center"  style="position: sticky;top: 0;background: white;">
              <div class="small_col2  flex items-center">
                <span class="cursor-pointer" @click="sortByColumn(0, titleObj[0].sort, titleObj[0].value)">{{titleObj[0].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[0].value) && titleObj[0].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[0].value) && !titleObj[0].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="smallx_col  flex items-center">
                <span class="cursor-pointer" @click="sortByColumn(1, titleObj[1].sort, titleObj[1].value)">{{titleObj[1].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[1].value) && titleObj[1].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[1].value) && !titleObj[1].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center centerText">
                <span class="cursor-pointer" @click="sortByColumn(2, titleObj[2].sort, titleObj[2].value)">{{titleObj[2].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[2].value) && titleObj[2].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[2].value) && !titleObj[2].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
              <div class="small_col  flex items-center rightText">
                <span class="cursor-pointer" @click="sortByColumn(3, titleObj[3].sort, titleObj[3].value)">{{titleObj[3].title}}</span>
                <span v-if="(pagination.sortBy === titleObj[3].value) && titleObj[3].sort"><i class="fas fa-sort-amount-down h-4 w-4 pl-1 text-primary2"></i></span>
                <p v-if="(pagination.sortBy === titleObj[3].value) && !titleObj[3].sort"><i class="fas fa-sort-amount-up h-4 w-4 pl-1 text-primary2"></i></p>
              </div>
            </div>
            <!-- <div class="w-full" >
              <div class="progress-bar" v-if="searchLoader">
                <div class="progress-bar-value"></div>
              </div>
            </div> -->
            <div :style="`max-height:` +  cardHight + `px; !important`" class="">
              <div class="flex border-r border-l border-b p-2 border-gray1 bg-white text-sm items-center py-1 cursor-pointer hover:bg-gray-50"
              :class="{'bg-gray-100': indexA % 2}"
              v-for="(value, indexA) in dealerPetstopListing" :key="indexA">
                <div class="small_col2">
                  <span class="heading-6-1 text-text1">{{value.sku}}</span>
                </div>
                <div class="smallx_col">
                  <span class="heading-6 text-text1">{{value.profitMargin | amountFormaterWithToFix}}</span>
                </div>
                <div class="small_col centerText">
                  <span class="heading-6 text-text1 ">{{value.sumOfItemPrice | amountFormaterWithToFix}}</span>
                </div>
                <div class="small_col rightText">
                  <span class="heading-6 text-text1 ">{{value.sumOfTax | amountFormaterWithToFix}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="layout m-2" v-if="dealerPetstopListing.length === 0 && firstLoading">
            <div class="flex p-3 text-sm text-primary2 bg-white border-blue-100 border-2 rounded-lg  items-center" role="alert">
              <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
              <span class="sr-only">Info</span>
              <div>
                <p class=" font-medium heading-4">No record found.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="box col-span-2 overflow-y-auto mx-2 shadow-md transition-transform delay-300 ease-in-out transition-2" :style="!isFilterOpen ? 'display: none' : ''">
          <GlobalFilter :callFrom="'Walmart'" :callParent="'accounting'"  @closeFilter="closeFilter" :isFilterOpen="isFilterOpen"/>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import moment from 'moment'
import axios from 'axios'
import {downloadFile} from '@/utils/common.js'
import API from '@/api/App.js'
import GlobalFilter from '@/View/Admin/Components/filterWithoutSelection.vue'
// import Pagination from '@/View/components/pagination.vue'
export default {
  components: {
    GlobalFilter,
  },
  data () {
    return {
      isFilterOpen: true,
      searchLoader: false,
      dealerPetstopListing: [],
      titleObj: [
        {title: 'Sku', icon: '', value: 'sku', sort: false},
        {title: 'Profit Margin', icon: '', value: 'profitMargin', sort: false},
        {title: 'Sum of Item Price (Order Total)', icon: '', value: 'sumOfItemPrice', sort: true},
        {title: 'Sum of Tax', icon: '', value: 'sumOfTax', sort: false},
      ],
      pagination: {
        sortBy: 'sumOfItemPrice',
        descending: true
      },
      filterObject: {
        startDate: '',
        endDate: '',
        minDate: '',
        maxDate: '',
      },
      cardHight: 0,
      mainHeight: 0,
      defaultStartDate: '',
      defaultEndDate: '',
      firstLoading: false,
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    this.cardHight = window.innerHeight - 171
    this.mainHeight = window.innerHeight - 90
    this.defaultEndDate = new Date()
    let currentDate = new Date()
    this.defaultStartDate = new Date(currentDate.setDate(currentDate.getDate() - 29))
    this.filterObject.startDate = this.defaultStartDate
    this.filterObject.endDate = this.defaultEndDate
  },
  watch: {
    filterObject: {
      handler () {
        console.log('filterObject', this.filterObject)
      },
      deep: true
    },
  },
  methods: {
    openCloseFilter () {
      if (this.isFilterOpen) {
        this.isFilterOpen = false
      } else {
        this.isFilterOpen = true
      }
      console.log('openCloseFilter')
    },
    applyFilter() {
      this.getListing()
    },
    closeFilter (data) {
      console.log('data', data)
      this.filterObject.startDate = data.startDate
      this.filterObject.endDate = data.endDate
      this.getListing()
    },
    getListing () {
      this.firstLoading = false
      console.log('this.filterObject.startDate 123 123', this.filterObject.startDate)
      console.log('this.filterObject.endDate 123 123', this.filterObject.endDate)
      let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
      let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait, it takes some time ...'})
      this.searchLoader = true
      API.COGSProductTextOnlyReportListing(
        utcSTART,
        utcEND,
        false,
        response => {
          this.dealerPetstopListing = response.Data === null ? [] : response.Data
          console.log(response.Data)
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.searchLoader = false
          this.firstLoading = true
        },
        error => {
          this.resetPaginationValues = false
          this.searchLoader = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    sortByColumn (i, val1, val2) {
      console.log('i', i)
      console.log('val1', val1)
      console.log('val2', val2)
      this.titleObj[i].sort = !val1
      this.pagination.descending = val1
      this.pagination.sortBy = val2
      console.log('this.pagination.descending', this.pagination.descending)
      if (this.pagination.descending) {
        console.log('click 1')
        this.dealerPetstopListing.sort(function (a, b) {
          console.log('a', a)
          console.log('b', b)
          if (val2 === 'profitMargin') {
            var nameA = a.profitMargin
            var nameB = b.profitMargin
          } else if (val2 === 'sku') {
            nameA = a.sku
            nameB = b.sku
          } else if (val2 === 'sumOfItemPrice') {
            nameA = a.sumOfItemPrice
            nameB = b.sumOfItemPrice
          } else if (val2 === 'sumOfTax') {
            nameA = a.sumOfTax
            nameB = b.sumOfTax
          }
          console.log('nameA-------------------- 1', nameA)
          console.log('nameB-------------------- 1', nameB)
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      } else {
        console.log('click 2')
        this.dealerPetstopListing.reverse(function (a, b) {
          if (val2 === 'profitMargin') {
            var nameA = a.profitMargin
            var nameB = b.profitMargin
          } else if (val2 === 'sku') {
            nameA = a.sku
            nameB = b.sku
          } else if (val2 === 'sumOfItemPrice') {
            nameA = a.sumOfItemPrice
            nameB = b.sumOfItemPrice
          } else if (val2 === 'sumOfTax') {
            nameA = a.sumOfTax
            nameB = b.sumOfTax
          }
          console.log('nameA-------------------- 2', nameA)
          console.log('nameB-------------------- 2', nameB)
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      }
      this.titleObj[i].isDesc = !this.titleObj[i].isDesc
    },
    async exportReport () {
      if (this.firstLoading && this.dealerPetstopListing.length > 0) {
        let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
        let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
        this.searchLoader = true
        let day1 = moment(this.filterObject.startDate).format("MM-DD-YY")
        let day2 = moment(this.filterObject.endDate).format("MM-DD-YY")
        let Time = 'COGS by Product Walmart-' + day1 + '-To-' + day2
        let data = new FormData()
        data.append('from', utcSTART)
        data.append('to', utcEND)
        data.append('export', true)
        await this.downloadAttachment('api/v1/analytics/dpp/walmart/cogs/product', `${Time}` + '.xlsx', data)
      }
    },
    async downloadAttachment (subUrl, fileName, formData) {
      let url = this.$store.getters.liveServerUrl + subUrl
      // If Want To cancel request
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      let originalFileName = fileName
      this.$store.dispatch('CancelDownloadRequest', source)
      // Show loader while downloading
      this.$store.dispatch('SetDownloadLoader', true)
      // Calling Api and waiting for response
      let response = await downloadFile(formData, url, source)
      // Checking for status if 200 == success
      if (response.status === 200) {
        this.$store.dispatch('CancelDownloadRequest', null)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data]))
        console.log(window.URL.createObjectURL(new Blob([response.data])))
        link.setAttribute('download', originalFileName)
        document.body.appendChild(link)
        link.click()
        // CLosing loader and reseting text in loader
        this.$store.dispatch('SetDownloadLoader', false)
        this.$store.dispatch('SetDownloadMessage', '')
      } else {
        this.$store.dispatch('CancelDownloadRequest', null)
        this.$store.dispatch('SetDownloadMessage', '')
        this.$store.dispatch('SetDownloadLoader', false)
      }
    },
    clearSearch () {
      this.itemSearchVal = ''
      this.reqStateList = this.StateList
    },
    clearSearch2 () {
      this.itemNameSearchVal = ''
      this.reqPaymentList = this.PaymentList
    },
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.centerText {
  text-align: center !important;
  justify-content: center !important;
}
.small_col2 {
  min-width: 260px !important;
  width: 260px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.smallx_col {
  min-width: 250px !important;
  width: 250px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small_col {
  min-width: 160px !important;
  width: 167px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.progress-bar {
  height: 2px;
  background-color: #0a7ef9;
  width: 100%;
  overflow: hidden;
}

.button_position {
  position: absolute;
  z-index: 10!important;
  height: 0px!important;
  /* position: fixed; */
}
.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #74b4f9;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
</style>
