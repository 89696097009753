<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white">
        <div>
          <WalmartChart></WalmartChart>
        </div>
        <div class="mt-8">
          <WalmartText></WalmartText>
        </div>
        <div class="mt-8">
          <channelChart></channelChart>
        </div>
        <div class="mt-8">
          <channelText></channelText>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import WalmartChart from './component/walmartChart.vue'
import WalmartText from './component/walmartText.vue'
import channelChart from './component/channelChart.vue'
import channelText from './component/channelText.vue'
export default {
  components: {
    WalmartChart,
    WalmartText,
    channelChart,
    channelText
  },
  data () {
    return {
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    document.title = 'COGS ◾ Walmart'
  },
  watch: {
  },
  methods: {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
</style>
